var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"ribbon-wrap"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"search-box"},[_c('span',{staticClass:"label"},[_vm._v("模糊搜索：")]),_c('a-input',{staticClass:"search-input",attrs:{"placeholder":"尝试搜索姓名、手机号等"},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)]),_c('div',{staticClass:"item"},[_c('div',{directives:[{name:"permission",rawName:"v-permission",value:('search'),expression:"'search'"}],staticClass:"btn-box"},[_c('a-button',{on:{"click":_vm.searchReset}},[_vm._v(" 重置 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.searchSubmit}},[_vm._v(" 查询 ")])],1)])]),_c('div',{staticClass:"main-content-header"},[_vm._m(0),_c('div',{staticClass:"item"},[_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:('export'),expression:"'export'"}],attrs:{"type":"primary","icon":"plus"},on:{"click":function($event){_vm.modalVisible = true}}},[_vm._v(" 新增企业 ")]),_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:('export'),expression:"'export'"}]},[_vm._v(" 导出代理企业花名册 ")])],1)]),_c('my-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"pagination":_vm.pagination,"loading":_vm.loading,"scroll":{ x: 1200, y: true }},on:{"change":_vm.handlePaginationChange},scopedSlots:_vm._u([{key:"status",fn:function(record){return [(record.status)?_c('a-badge',{attrs:{"status":"success","text":_vm.statuText[record.status]}}):_c('a-badge',{attrs:{"status":"default","text":_vm.statuText[record.status]}})]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"}},[_c('router-link',{attrs:{"to":{
              path: '/agentEnterpriseInfo',
              query: { id: record.id }
            }}},[_vm._v(" 更多详情 ")])],1),_c('a-button',{directives:[{name:"permission",rawName:"v-permission",value:('edit'),expression:"'edit'"}],attrs:{"type":"link"}},[_c('router-link',{attrs:{"to":{
              path: '/',
              query: { id: record.id }
            }}},[_vm._v(" 编辑资料 ")])],1),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.setStatus(record.id, record.status)}}},[(record.status === 1)?_c('span',[_vm._v("禁用")]):_c('span',[_vm._v("启用")])])]}}])})],1),_c('a-modal',{attrs:{"title":"新增企业"},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('a-form-model-item',{ref:"name",attrs:{"label":"企业名称","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入企业名称"},on:{"blur":function () {
              _vm.$refs.name.onFieldBlur()
            }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{ref:"person",attrs:{"label":"企业法人","prop":"person"}},[_c('a-input',{attrs:{"placeholder":"请输入企业法人姓名"},on:{"blur":function () {
              _vm.$refs.person.onFieldBlur()
            }},model:{value:(_vm.form.person),callback:function ($$v) {_vm.$set(_vm.form, "person", $$v)},expression:"form.person"}})],1),_c('a-form-model-item',{ref:"head",attrs:{"label":"负责人姓名","prop":"head"}},[_c('a-input',{attrs:{"placeholder":"请输入姓名"},on:{"blur":function () {
              _vm.$refs.head.onFieldBlur()
            }},model:{value:(_vm.form.head),callback:function ($$v) {_vm.$set(_vm.form, "head", $$v)},expression:"form.head"}})],1),_c('a-form-model-item',{ref:"mobile",attrs:{"label":"负责人联系方式","prop":"mobile"}},[_c('a-input',{attrs:{"placeholder":"请输入11位手机号"},on:{"blur":function () {
              _vm.$refs.mobile.onFieldBlur()
            }},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", $$v)},expression:"form.mobile"}})],1)],1),_c('template',{slot:"footer"},[_c('a-button',{key:"refuse",on:{"click":_vm.handleCancel}},[_vm._v(" 关 闭 ")]),_c('a-button',{key:"agree",attrs:{"type":"primary","loading":_vm.modalLoading},on:{"click":_vm.handleSubmit}},[_vm._v(" 提 交 ")])],1)],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v("代理企业列表")])])}]

export { render, staticRenderFns }