<template>
  <!-- 代理企业管理 -->
  <div class="container">
    <a-page-header :title="title" />

    <div class="main-content">
      <div class="ribbon-wrap">
        <div class="item">
          <div class="search-box">
            <span class="label">模糊搜索：</span>
            <a-input
              class="search-input"
              v-model="searchValue"
              placeholder="尝试搜索姓名、手机号等"
            />
          </div>
        </div>
        <div class="item">
          <div class="btn-box" v-permission="'search'">
            <a-button @click="searchReset">
              重置
            </a-button>
            <a-button type="primary" @click="searchSubmit">
              查询
            </a-button>
          </div>
        </div>
      </div>

      <div class="main-content-header">
        <div class="item">
          <div class="title">代理企业列表</div>
        </div>
        <div class="item">
          <a-button
            type="primary"
            icon="plus"
            v-permission="'export'"
            @click="modalVisible = true"
          >
            新增企业
          </a-button>
          <a-button v-permission="'export'">
            导出代理企业花名册
          </a-button>
        </div>
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1200, y: true }"
        @change="handlePaginationChange"
      >
        <template slot="status" slot-scope="record">
          <a-badge
            status="success"
            :text="statuText[record.status]"
            v-if="record.status"
          />
          <a-badge status="default" :text="statuText[record.status]" v-else />
        </template>
        <template slot="action" slot-scope="record">
          <a-button type="link">
            <router-link
              :to="{
                path: '/agentEnterpriseInfo',
                query: { id: record.id }
              }"
            >
              更多详情
            </router-link>
          </a-button>
          <a-button type="link" v-permission="'edit'">
            <router-link
              :to="{
                path: '/',
                query: { id: record.id }
              }"
            >
              编辑资料
            </router-link>
          </a-button>
          <a-button type="link" @click="setStatus(record.id, record.status)">
            <span v-if="record.status === 1">禁用</span>
            <span v-else>启用</span>
          </a-button>
        </template>
      </my-table>
    </div>

    <a-modal v-model="modalVisible" title="新增企业">
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <a-form-model-item ref="name" label="企业名称" prop="name">
          <a-input
            v-model="form.name"
            placeholder="请输入企业名称"
            @blur="
              () => {
                $refs.name.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="person" label="企业法人" prop="person">
          <a-input
            v-model="form.person"
            placeholder="请输入企业法人姓名"
            @blur="
              () => {
                $refs.person.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="head" label="负责人姓名" prop="head">
          <a-input
            v-model="form.head"
            placeholder="请输入姓名"
            @blur="
              () => {
                $refs.head.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item ref="mobile" label="负责人联系方式" prop="mobile">
          <a-input
            v-model="form.mobile"
            placeholder="请输入11位手机号"
            @blur="
              () => {
                $refs.mobile.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="refuse" @click="handleCancel">
          关 闭
        </a-button>
        <a-button
          key="agree"
          type="primary"
          :loading="modalLoading"
          @click="handleSubmit"
        >
          提 交
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const checkMobile = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入11位手机号'))
  } else {
    if (value.length < 11) {
      callback(new Error('请输入正确的11位号码'))
    } else {
      callback()
    }
  }
}
export default {
  data() {
    return {
      searchValue: '',
      statuText: {
        1: '启用',
        0: '禁用'
      },
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 100,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '企业名称',
          dataIndex: 'name'
        },
        {
          title: '企业法人',
          width: 150,
          dataIndex: 'person'
        },
        {
          title: '负责人姓名',
          width: 150,
          dataIndex: 'head'
        },
        {
          title: '负责人联系方式',
          width: 200,
          dataIndex: 'mobile'
        },
        {
          title: '操作',
          width: 300,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      loading: true,
      pagination: {
        current: 1,
        total: 0
      },
      modalVisible: false,
      modalLoading: false,
      form: {
        name: '',
        person: '',
        head: '',
        mobile: ''
      },
      rules: {
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        person: [
          { required: true, message: '请输入企业法人姓名', trigger: 'blur' }
        ],
        head: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        mobile: [{ required: true, validator: checkMobile, trigger: 'blur' }]
      }
    }
  },
  created() {
    this.title = this.$getPageTitle()
    this.getAgentEnterprise()
  },
  methods: {
    searchReset() {
      // 重置搜索
      this.searchValue = ''
      this.getAgentEnterprise()
    },
    searchSubmit() {
      // 搜索
      if (!this.searchValue) return
      this.pagination.current = 1
      this.loading = true
      this.getAgentEnterprise()
    },
    handlePaginationChange(e) {
      this.loading = true
      this.pagination.current = e.current
      this.getAgentEnterprise()
    },
    getAgentEnterprise() {
      // 获取列表
      const data = {
        page: this.pagination.current,
        limit: 10
      }
      if (this.searchValue) {
        this.loading = true
        data.keyword = this.searchValue
        data.page = 1
        this.pagination.current = 1
      }
      this.$axios.getAgentEnterprise(data).then((res) => {
        this.dataSource = res.data.data.list
        this.pagination.total = res.data.data.count
        this.loading = false
      })
    },
    setStatus(id, status) {
      // 设置状态
      let title = ''
      if (status === 1) {
        title = '确定禁用？'
        status = 0
      } else {
        title = '确定启用？'
        status = 1
      }
      this.$confirm({
        title: title,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          // const data = {
          //   id,
          //   status
          // }
          // this.$axios.setApprenticeStatus(data).then(() => {
          //   this.$message.success('操作成功')
          //   this.getApprentice()
          // })
        }
      })
    },
    handleCancel() {
      this.modalVisible = false
      this.modalLoading = false
      this.$refs.ruleForm.resetFields()
    },
    handleSubmit() {
      // 提交
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.modalLoading = true
          this.$axios.addAgentEnterprise(this.form).then(() => {
            this.handleCancel()
            this.$message.success('添加成功')
            this.getAgentEnterprise()
          })
        }
      })
    }
  }
}
</script>

<style scoped></style>
